<template>
  <div
    v-if="loading"
    class="d-flex justify-center"
  >
    <!-- Loading circle -->
    <v-progress-circular
      :size="50"
      indeterminate
      color="primary"
    >
    </v-progress-circular>
  </div>
  <div v-else>
    <NewEventStepper
      :event-data="eventSavedData"
      :new-event="false"
      :not-available-lang="notAvailableLang"
      @submit="editEventData"
    ></NewEventStepper>
  </div>
</template>

<script>
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line import/no-cycle
import NewEventStepper from '@/components/EventDetails/Form/NewEventStepper.vue'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import useSpecialMealsTypes from '@/composables/useSpecialMealsTypes'

export default {
  name: 'EventSettings',
  components: {
    NewEventStepper,
  },

  setup() {
    const { mealsTypes } = useSpecialMealsTypes()
    const toast = useToast()
    const showToast = () => toast.error(i18n.t('errorManageEvents'))
    const loading = ref(false)
    const notAvailableLang = ref()
    const eventSavedData = store.state.eventData
    const nonRemovableLang = () => {
      store.dispatch('nonRemovableLang', { id: store.state.eventData.id }).then(res => {
        notAvailableLang.value = res.data
      })
    }
    nonRemovableLang()

    const editEventData = (eventData, errorHandler) => {
      loading.value = true
      store
        .dispatch('editEvent', { id: store.state.eventData.id, eventData })
        .then(res => {
          router.push({ name: 'home-page', params: { id: res.data.id } })
        })
        .catch(() => {
          showToast()
          errorHandler()
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      eventSavedData,
      editEventData,
      loading,
      showToast,
      notAvailableLang,
      mealsTypes,
    }
  },
}
</script>
